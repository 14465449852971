import {getToken} from '../porter-package/auth';

const API_HOST = 'https://api.beamfire.io';

// const API_HOST = 'https://local.beamfire.io:2020';

export type Strategy = 'sms' | 'email' | 'totp' | 'totp-new';

const DEFAULT_HEADERS = {
	'Content-Type': 'application/json'
};

type AuthHeaders = {
	'Content-Type': string;
	Authorization: string;
}
export const getAuthHeaders = (): AuthHeaders => {
	const token = getToken();
	return {
		...DEFAULT_HEADERS,
		Authorization: token || ''
	};
};

export const fetchConfig = async (): Promise<{key: string;}> => {
	try {
		const response = await fetch(`${API_HOST}/c`, {
			method: 'GET',
			headers: getAuthHeaders()
		});

		const json = await response.json();
		return json;

	} catch (error: any) {
		return error;
	}
};

export const fetchReports = async (domain: string): Promise<Reports> => {
	try {
		const response = await fetch(`${API_HOST}/r/${domain}`, {
			method: 'GET',
			headers: getAuthHeaders()
		});

		const json = await response.json();
		return json;

	} catch (error: any) {
		return error;
	}
};

export const runSiteAudit = async (domain: string): Promise<void> => {
	try {
		const response = await fetch(`${API_HOST}/r/${domain}/site`, {
			method: 'POST',
			headers: getAuthHeaders()
		});

		if (!response.ok) {
			throw new Error('Invalid Response');
		}
	} catch (error: any) {
		throw new Error('Unknown Response');
	}
};

export const runSecurityAudit = async (domain: string): Promise<void> => {
	try {
		const response = await fetch(`${API_HOST}/r/${domain}/security`, {
			method: 'POST',
			headers: getAuthHeaders()
		});
		if (!response.ok) {
			throw new Error('Invalid Response');
		}
	} catch (error: any) {
		throw new Error('Unknown Response');
	}
};

export const parrotConfig = async (body: Settings): Promise<void> => {
	try {
		const response = await fetch(`${API_HOST}/c`, {
			method: 'POST',
			headers: getAuthHeaders(),
			body: JSON.stringify(body)
		});
		if (!response.ok) {
			throw new Error('Invalid Response');
		}
	} catch (error: any) {
		throw new Error('Unknown Response');
	}
};

export const getArtifact = async (domain: string, type: string, id: string): Promise<string> => {
	try {
		const response = await fetch(`${API_HOST}/r/${domain}/${type}/${id}`, {
			method: 'GET',
			headers: getAuthHeaders()
		});

		return response.text();

	} catch (error: any) {
		return error;
	}
};
