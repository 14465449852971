import React, {FC, useContext, useCallback, useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Toast from 'react-bootstrap/Toast';
import {IChangeEvent, FormProps} from '@rjsf/core';
import Form from '@rjsf/bootstrap-4';
import {fetchConfig, parrotConfig} from '../../utils/beamfireClient';
import safeNavigate from '../../utils/safeNavigate';
import {
	UserContext,
	isSingleJsonApiResource,
	JsonApiResponse,
	getMetaPrivate,
	setMetaPrivate
} from '../../porter-package';
import AccountContainer from '../../containers/AccountContainer';

const SCHEMA: FormProps<Settings>['schema'] = {
	type: 'object',
	required: ['domains'],
	title: '',
	properties: {
		slackWebhook: {
			type: 'string',
			title: 'Slack Webhook Uri',
			format: 'uri'
		},
		domains: {
			type: ['array', 'null'],
			title: 'Application Domains',
			description: 'Domains to Be Audited',
			uniqueItems: true,
			items: {
				type: ['object', 'null'],
				title: '',
				properties: {
					domain: {
						type: 'string',
						title: 'Domain',
						format: 'uri'
					},
					environment: {
						type: 'string',
						title: 'Runtime Environment',
						enum: [
							'production',
							'staging',
							'qa',
							'integration',
							'development'
						]
					},
					targetPlatform: {
						type: 'string',
						title: 'Target Platform',
						enum: [
							'all',
							'mobile',
							'desktop'
						]
					},
					auditAjax: {
						type: 'boolean',
						title: 'Audit Ajax Requests'
					},
					failureThreshold: {
						type: 'number',
						maximum: 100,
						minimum: 50,
						default: 90,
						title: 'Minimum Passing Percentage'
					}
				}
			}
		}
	}
};

const Account: FC = () => {
	const context = useContext(UserContext);
	if (!context.isLoggedIn) {
		safeNavigate('/log-in/');
		return null;
	}

	const [response, setResponse] = useState<undefined | JsonApiResponse<Settings>>(undefined);
	const [config, setConfig] = useState<undefined | {key: string}>(undefined);
	const [open, setOpen] = useState(false);
	const [error, setError] = useState<undefined | true>(undefined);

	useEffect(() => {
		getMetaPrivate<Settings>()
			.then((res) => setResponse(res))
			.catch(() => {
				setError(true);
			});
	}, []);

	useEffect(() => {
		fetchConfig()
			.then((res) => setConfig(res))
			.catch(() => {
				setError(true);
			});
	}, []);

	const handleSubmit = useCallback(async (data: IChangeEvent<Settings>) => {
		const {formData} = data;
		setOpen(true);
		parrotConfig(formData)
			.catch(() => {
				setError(true);
			})
			.then(() => setMetaPrivate<Settings>(formData))
			.then((res) => setResponse(res))
			.catch(() => {
				setError(true);
			});
	}, [setError]);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, [open]);

	const PLAN_MAX = 10;

	return <AccountContainer title="Settings">
		<Card>
			<Card.Body>
				<Card.Title>
					My Account &gt; Settings
				</Card.Title>
				<Card.Subtitle>
					Current Usage:{' '}
					{
						response && isSingleJsonApiResource<Settings>(response) ?
							<strong>{response.data.attributes?.private?.domains?.length}/{PLAN_MAX}</strong> :
							null
					}
					<br />
					<small>Your plan allows for {PLAN_MAX} domains</small>
				</Card.Subtitle>
				<Card.Body>
					{
						!response ?
							null :
							isSingleJsonApiResource<Settings>(response) ?
								response.data.attributes ?
									<Form
										onSubmit={handleSubmit}
										formData={response.data.attributes.private}
										schema={SCHEMA}
									/> :
									null :
								null
					}
					{
						error ?
							'Something went wrong' :
							null
					}
					<Toast
						autohide
						onClose={handleClose}
						delay={3000}
						show={open}>
						<Toast.Header>
							<strong className="me-auto">Updated</strong>
						</Toast.Header>
						<Toast.Body>
							Your domains have been updated and will be used in the next interval.
						</Toast.Body>
					</Toast>
				</Card.Body>
				<Card.Text as="div">
					<Table
						striped
						bordered
						hover>
						<thead>
							<tr>
								<th colSpan={2}>
									For each domain listed, please ensure that the following DNS record is set:
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td><pre>TXT</pre></td>
								<td><pre>beamfire-key={config ? config.key : 'loading..'}</pre></td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<th colSpan={2}>
									<p>
										<small>
											This record simply informs us that you have control over
											the DNS record with the intent to audit it.
										</small>
									</p>
									<p>
										<small>
											Domains without this DNS record will be excluded from scans.*
										</small>
									</p>
									<p>
										<small>
											<strong style={{color: 'red'}}>
												*Upcoming security controls enforcement will require this DNS{' '}
												record on all domains, not just the primary one.
												<br />If your account was created before 01/01/2022,{' '}
												your previous DNS keys will need to be updated.
											</strong>
										</small>
									</p>
								</th>
							</tr>
						</tfoot>
					</Table>
				</Card.Text>
			</Card.Body>
		</Card>
	</AccountContainer>;
};

export default Account;
